.swiper-partner-view {
  margin-top: 146px;
  height: 435px;
  background-color: #313131;
  padding-left: 10%;
  padding-right: 10%;
}
.swiper-partner-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.partner-img {
  width: 162px;
  height: 162px;
  object-fit: cover;
  border-radius: 80px;
}
.mySwiper {
  top: -81px !important;
}
.partner-title {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  /* or 150% */

  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin-top: 56px;
}

.partner-footer {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;
  letter-spacing: -0.02em;

  /* Neutral/White */

  color: #ffffff;
  margin-top: 56px;

  opacity: 0.8;
}
.img-partner {
  margin-right: 40px;
  margin-left: 40px;
}
@media (max-width: 1024px) {
  .partner-title {
    font-size: 18px;
    line-height: 30px;
  }
  .partner-footer {
    font-size: 16px;
    line-height: 24px;
  }
  .img-partner {
    transform: scale(0.7);
  }
  .boder-ct {
    border: 1px solid #da9e26;
    padding-bottom: 100px;
  }
  .boder-ct2 {
    border: 1px solid #da9e26;
    padding-bottom: 100px;
    width: 96%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -10px;
    padding-top: 30px;
  }
  .swiper-partner-view {
    margin-top: -105px;
  }
  .partner-img {
    width: 80px;
    height: 80px;
  }
  .mySwiper {
    top: -41px !important;
  }
  .partner-title {
    margin-top: 24px;
    font-size: 18px;
    line-height: 30px;
  }
  .partner-footer {
    margin-top: 24px;
  }
  .swiper-partner-view {
    height: 289px;
  }
  .img-partner {
    opacity: 1;
    margin-right: 0px;
    margin-left: 0px;
  }
}
.swiper-button-next,
.swiper-button-prev {
  color: #da9e26 !important;
  top: 60% !important;
}
