@media (max-width: 1024px) {
  .title-home {
    font-size: 24px;
    line-height: 32px;
    flex: 1;
  }
}

.news-box {
  box-shadow: 5px 13px 25px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}
.title-new {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  letter-spacing: -0.02em;

  /* Neutral/Grey 1 */

  color: #313131;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.new-item {
  padding: 16px;
}
.item-time {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: -0.02em;

  /* Neutral/Grey 2 */

  color: #767676;
}
.item-see-now {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: -0.02em;

  /* Primary/Normal */

  color: #da9e26;
}
.view-opacity-right-new {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.view-opacity-left-new {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
