.about-title1 {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #da9e26;
  text-transform: uppercase;
}
.about-content {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #767676;
  margin-top: 24px;
}
.about-box {
  margin-top: 56px;
  width: 256px;
  height: 48px;
  border: 1px solid #da9e26;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  align-items: center;
}
.about-box-title {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  margin-left: 24px;
  color: #da9e26;
}
.about-image-border {
  width: 100%;
  height: 300px;
  border: 1px solid #da9e26;
  box-sizing: border-box;
  border-radius: 4px;
}
.about-image-view {
  width: 100%;
  height: 300px;
  box-sizing: border-box;
  border-radius: 4px;
  object-fit: cover;
  margin-left: -12px;
  margin-top: -12px;
}
.about-box:hover {
  cursor: pointer;
  border: 1px solid #efb031;
}
.arrow-see-more2 {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}
.arrow-see-more {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  content: url("../../../assets//images/arrow2.png");
}
.about-box:hover .about-box-title {
  color: #efb031;
}

.about-box:hover .arrow-see-more {
  color: #efb031;
  width: 24px;
  height: 24px;
  content: url("../../../assets//images/arrowhover.png");
  object-fit: cover;
}

.home-box-detail:hover .arrow-see-more {
  color: #efb031;
  width: 24px;
  height: 24px;
  content: url("../../../assets//images/arrowhover.png");
  object-fit: cover;
}
@media (max-width: 1024px) {
  .about-box {
    margin-top: 56px;
    width: 100%;
    height: 48px;
  }
}
