.view-bottom {
  text-align: center;
  background-color: #313131;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.avt-partner {
  width: 88px;
  height: 88px;
  border-radius: 44px;
  background-color: #e5e5e5;
  margin-left: auto;
  margin-right: auto;
}
.view-partner {
  position: relative;
  top: -44px;
}
.txt-comment {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin-top: 40px;
  margin-left: 44px;
  margin-right: 44px;
}
.txt-name-partner {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  opacity: 0.8;
  margin-top: 40px;
  margin-left: 110px;
  margin-right: 110px;
}
@media (max-width: 1024px) {
  .view-bottom {
    border-radius: 0;
  }
}
