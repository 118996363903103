.service-box {
  height: 180px;
  left: 0px;
  top: 0px;
  /* Neutral/White */
  background: #ffffff;
  /* Shadow/1 */
  box-shadow: 0px 13px 25px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.service-title {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 52px;
  letter-spacing: -0.02em;
  color: #000000;
}
.service-content {
  margin-top: 16px;
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #767676;
}
.service-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.service-box img {
  width: 80px;
  height: 80px;
}
.service-box-title {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  margin-top: 12px;
  color: #313131;
  margin-left: 15px;
  margin-right: 15px;
}

.my-service-ct:hover {
  transition: ease-in-out 0.1s;
  transform: scale(1.02);
  box-shadow: 0px 13px 15px rgba(0, 0, 0, 0.05);
}
@media (max-width: 1024px) {
  .service-title {
    font-size: 24px;
    line-height: 32px;
  }
}
