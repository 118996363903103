.top-bar {
  box-shadow: 0px 13px 15px rgba(0, 0, 0, 0.05) !important;
}
.top-bar:hover {
  background-color: #fff;
}
.view-link-item {
  height: 80px;
  margin-right: 40px;
  padding-top: 32px;
  /* align-items: center; */
}
.link-item {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: -0.02em;
  color: #666666 !important;
  text-decoration: none !important;
}
/* li.different:hover {
  border: none;
}
li:hover {
  border-bottom: 5px solid #ffffff;
}
.link-item::after {
  content: "";
  position: absolute;
  width: 0px;
  height: 5px;
  left: 50%;
  bottom: 0;
  background-color: white;
  transition: all ease-in-out 0.2s;
}
.link-item:hover::after {
  width: 100%;
  left: 0;
} */
.navigation__checkbox {
  display: none;
}
.navigation__button {
  /* background-color: #fff; */
  height: 24px;
  width: 24px;
  border-radius: 50%;
  position: fixed;
  top: 12px;
  right: 15px;
  z-index: 2000;
  /* box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.2); */
  text-align: center;
  cursor: pointer;
}
.navigation__background {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  position: fixed;
  top: -10px;
  right: -10px;
  /* background-image: radial-gradient(#2998ff, #5643fa); */
  z-index: 1000;
  /* opacity: 0; */
  transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
}
.navigation__nav {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  opacity: 0;
  width: 0;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.navigation__list {
  list-style: none;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 99;
  padding-left: 0;
}
.navigation__list li {
  margin: 0;
  padding: 0px 0px 0px 16px;
}
.navigation__link,
.navigation__link:visited {
  text-align: left;
  color: #767676;
  text-decoration: none;
  transition: all 0.4s;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  padding-top: 32px;
  padding-bottom: 24px;
}
.navigation__link:hover,
.navigation__link:active {
  background-position: 100%;
  color: "rgba(255,255,255,0.5)";
  /* transform: translateX(1rem); */
}
.navigation__checkbox:checked ~ .navigation__background {
  transform: scale(1000);
  opacity: 1;
}

.navigation__checkbox:checked ~ .navigation__nav {
  opacity: 1;
  width: 100%;
}
.navigation__icon {
  position: relative;
  margin-top: 2px;
}
.navigation__icon,
.navigation__icon::before,
.navigation__icon::after {
  width: 24px;
  height: 2px;
  background-color: #da9e26;
  display: inline-block;
}
.navigation__icon::before,
.navigation__icon::after {
  content: "";
  position: absolute;
  left: 0;
  transition: all 0.2s;
}
.navigation__icon::before {
  top: -6px;
}
.navigation__icon::after {
  bottom: -6px;
}
.navigation__button:hover .navigation__icon::before {
  top: -6px;
}
.navigation__button:hover .navigation__icon::after {
  bottom: -6px;
}
.navigation__checkbox:checked + .navigation__button .navigation__icon {
  background-color: transparent;
}
.navigation__checkbox:checked + .navigation__button .navigation__icon::before {
  transform: rotate(135deg);
  top: 0;
}
.navigation__checkbox:checked + .navigation__button .navigation__icon::after {
  transform: rotate(-135deg);
  bottom: 0;
}
.active {
  border-bottom: 2px solid #da9e26;
}
.active-mb {
  display: table-cell;
  border-bottom: 2px solid #da9e26;
  position: static;
  left: 0px;
  top: 0px;
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: -0.02em;
  text-decoration: none !important;
  color: #000000 !important;
}
.title-active {
  position: static;
  width: 80px;
  height: 24px;
  left: 0px;
  top: 0px;

  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: -0.02em;
  text-decoration: none !important;
  color: #000000 !important;
}
.title-pathName {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.02em;
}
