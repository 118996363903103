.contact-title {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: -0.02em;
  color: #ffffff;
}
.bg-contact {
  border-radius: 8px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(rgba(0, 0, 0, 0.9))
  );
  /* background: linear-gradient(transparent, rgba(0, 0, 0, 0.9)); */
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  color: #fff;
  font-weight: 700;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}
.view-contact {
  margin-top: auto;
  margin-left: 96px;
  margin-right: 96px;
  margin-bottom: 49px;
  width: 100%;
}
.contact-input-view {
  margin-top: 32px;
  height: 80px;
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  align-items: center;
}
.contact-content {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.02em;

  color: #dbdbdb;
}
.box-phone {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  /* Neutral/White */
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 61px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 16px 4px 16px;
  margin-right: 8px;
  margin-left: 8px;
}
@media (max-width: 1024px) {
  .contact-title {
    font-size: 24px;
    line-height: 32px;
  }
  .view-contact {
    margin-top: auto;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 49px;
    width: 100%;
  }
  .contact-input-view {
    height: 52px;
  }
  .input-contact-md {
    font-size: 14px;
    line-height: 20px;
  }
}
.input-contact-md {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 24px;
  width: 100%;
  height: 54px;
  background: #ffffff;
  border-radius: 6px;
  border: 0px solid white;
  margin-bottom: 16px;
  color: #313131;
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}
.input-contact-md::placeholder {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #ababab;
}
.input-contact-md:focus {
  outline-width: 0;
}
.box-phone-mb {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  /* Neutral/White */
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 61px;
  justify-content: center;
  align-items: center;
  padding: 4px 16px 4px 16px;
  margin-right: 8px;
  margin-left: 8px;
}
