.slider-view {
  border-radius: 4px;
}
.slider-view:hover {
  cursor: pointer;
}
.bg-slider {
  border-radius: 4px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(rgba(0, 0, 0, 0.9))
  );
  /* background: linear-gradient(transparent, rgba(0, 0, 0, 0.9)); */
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.5) 14.88%,
    rgba(0, 0, 0, 0) 69.11%
  );
  color: #fff;
  font-weight: 700;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  align-items: center;
}
.bg-slider-mb {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.5) 14.88%,
    rgba(0, 0, 0, 0) 69.11%
  );
  display: flex;
  height: 100%;
  width: 100%;
}
.slider-content {
  margin-left: 160px;
}

.slider-title {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;
  /* identical to box height */
  letter-spacing: -0.02em;
  color: #ffffff;
}
.slider-header {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #da9e26;
}
.slider-description {
  width: 65%;
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */
  margin-top: 24px;
  letter-spacing: -0.02em;
  color: #ffffff;
}
.slider-box {
  background: rgba(255, 255, 255, 0.2);
  /* Primary/Normal */

  border: 1px solid #da9e26;
  box-sizing: border-box;
  border-radius: 61px;
  padding: 4px 16px 4px 16px;
  margin-right: 8px;
}
.slider-box:hover {
  background: #da9e26;
  cursor: pointer;
}
.view-opacity-right {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  height: 360px;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-next {
  width: 80px;
  height: 80px;
}
.button-next :hover {
  cursor: pointer;
}

.isCurrentHigh {
  width: 100%;
  position: fixed;
  top: 48px;
  padding-top: 28px;
  background-color: #fff;
  padding-bottom: 8px;
  left: 0;
}
.view-opacity-left {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  height: 360px;
  /* background: rgba(255, 255, 255, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
}
.view-opacity-left-img {
  border-radius: 50px;
  transform: rotate(180deg);
  width: 56px;
  height: 56px;
}
.view-opacity-left-hover-ct:hover {
  transition: ease-in-out 0.1s;
  transform: rotate(180deg) scale(1.02);
  cursor: pointer;
  box-shadow: 0px 13px 15px rgba(0, 0, 0, 0.05);
}
