.footer-view {
  padding-left: 70px;
  padding-right: 70px;
  min-height: 288px;
  background-color: #313131;
  padding-bottom: 50px;
}

@media (max-width: 1024px) {
  .footer-view {
    padding-left: 15px;
    padding-right: 15px;
    min-height: 288px;
    background-color: #313131;
    padding-bottom: 50px;
  }
}
.link-item-footer {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #dddddd !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  margin-left: 24px !important;
}
.view-top-footer {
  border-bottom: 2px solid #797979;
}
.view-bottom-footer {
  margin-top: 15px;
}
.title-bottom {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */
  letter-spacing: -0.02em;
  color: #b7b7b7;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
